import "../../../App.css";
import "@fontsource/montserrat";
import "./MapandListing.css";
import MapListingCards from "./MapListingCards";
import MapBox from "../GoogleMaps/MapBox";
import { useState, useEffect, useRef } from "react";
import { RotatingLines } from "react-loader-spinner";
import MapIcon from "@mui/icons-material/Map";
import { useSelector } from "react-redux";
import ResultNotFound from "../ResultNotFound/ResulutNotFound";
import ListingSeo from "./ListingsSeo";
import SearchingSuggestion from "../SearchingSuggestion";

export default function MapandListing(props) {
  const {
    MixedListing,
    showListing,
    MapListing,
    scrollPage,
    setScrollPage,
    isLoading,
    totalPageCount,
    cardRef,
    // setScrollLoading,
    // sortInitiated,
    // setSortInitiated,
    loadMore,
    setLoadMore,
    handleListClick,
    handleMapClick,
    toggleIcon,
    insidePolygon,
    setInsidePolygon,
    categoryLocation,
    centerPopUP,
    resetTrigger,
    isFocusOnMap,
    isSearchPage,
    setNewInputVal,
    setSearchResTitle,
    setSearchValue,
    setSearchResDes,
    setSelectedValues,
    searchValue,
  } = props;
  // const [isFetching, setIsFetching] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const filterDataSlice = useSelector((state) => state.filterDataSlice);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleFilteredData = (length) => {
  //   onFilteredData1(length);
  // };

  // const handlePageChange = () => {
  //   if (scrollPage < totalPageCount && !isFetching) {
  //     setScrollLoading(true);
  //     setIsFetching(true);
  //     setTimeout(() => {
  //       setIsFetching(false);
  //       setScrollPage((prevPage) => prevPage + 1);
  //     }, 1000);
  //   }
  // };

  // let lastScrollTop = 0;

  // useEffect(() => {
  // //   const container = document.getElementById("mapcard");
  // //   const handleScroll = () => {
  // //     if (container) {
  // //       const { scrollTop, scrollHeight, clientHeight } = container;
  // //       const threshold = 10;

  // //       if (sortInitiated && scrollTop > lastScrollTop) {
  // //         // Scroll to the top if sort has been initiated and scrolling down
  // //         container.scrollTop = 0;
  // //         // Reset sort initiation state
  // //         setSortInitiated(false);
  // //       } else if (
  // //         !sortInitiated &&
  // //         scrollTop > lastScrollTop &&
  // //         scrollTop + clientHeight >= scrollHeight - threshold
  // //       ) {
  // //         // Load more data if not sorting and scrolling down near the bottom
  // //         handlePageChange();
  // //       }
  // //       // eslint-disable-next-line
  // //       lastScrollTop = scrollTop;
  // //     }
  // //   };
  // //   if (container) {
  // //     container.addEventListener("scroll", handleScroll);
  // //     return () => {
  // //       container.removeEventListener("scroll", handleScroll);
  // //     };
  // //   }
  // // }, [handlePageChange, sortInitiated]);

  //listing Load More Button Work
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (scrollPage >= totalPageCount) {
      setShowMore(false);
    } else if (totalPageCount > scrollPage) {
      setShowMore(true);
    }
  }, [scrollPage, totalPageCount]);

  const handleMoreDeals = () => {
    if (!loadMore) {
      setLoadMore(true);
      setScrollPage((prevPage) => prevPage + 1);
      handleResize();
    }
  };

  const handleResizeAndScroll = () => {
    handleResize();
  };

  const [showButton, setShowButton] = useState(true);

  const handleResize = () => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.clientHeight;
      setShowButton(window.innerHeight + window.scrollY - 520 < cardHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeAndScroll);
    window.addEventListener("scroll", handleResizeAndScroll);
    handleResizeAndScroll();
    return () => {
      window.removeEventListener("resize", handleResizeAndScroll);
      window.removeEventListener("scroll", handleResizeAndScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showMapButton, setShowMapButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const maxScrollHeight = 300;
      if (scrollPosition <= maxScrollHeight) {
        setShowMapButton(true);
      } else {
        setShowMapButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [hoveredSlug, setHoveredSlug] = useState("");

  const handleHoveredListCard = (slug) => {
    setHoveredSlug(slug);
  };
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && mapRef.current.resize) {
      mapRef.current.resize();
    }
  }, [showListing, MapListing]);

  const popularSearches = [
    {
      label: "Condo in Toronto",
      query: "Toronto",
      building: "Condo",
    },
    {
      label: "TownHouse in Toronto",
      query: "Toronto",
      building: "TownHouse",
    },
    {
      label: "Detached in Toronto",
      query: "Toronto",
      building: "Detached",
    },
    {
      label: "Condo in Mississauga",
      query: "Mississauga",
      building: "Condo",
    },
    {
      label: "TownHouse in Mississauga",
      query: "Mississauga",
      building: "TownHouse",
    },
    {
      label: "Detached in Mississauga",
      query: "Mississauga",
      building: "Detached",
    },
    {
      label: "Condo in Vaughan",
      query: "Vaughan",
      building: "Condo",
    },
    {
      label: "TownHouse in Vaughan",
      query: "Vaughan",
      building: "TownHouse",
    },
    {
      label: "Detached in Vaughan",
      query: "Vaughan",
      building: "Detached",
    },
  ];

  const nearBycities = [
    {
      label: "Assignments in Toronto",
      query: "Toronto",
    },
    {
      label: "Assignments in Mississauga",
      query: "Mississauga",
    },
    {
      label: "Assignments in Vaughan",
      query: "Vaughan",
    },
    {
      label: "Assignments in Oakville",
      query: "Oakville",
    },
    {
      label: "Assignments in North York",
      query: "North York",
    },
    {
      label: "Assignments in Scarborough",
      query: "Scarborough",
    },
    {
      label: "Assignments in Markham",
      query: "Markham",
    },
    {
      label: "Assignments in Brampton",
      query: "Brampton",
    },
    {
      label: "Assignments in Milton",
      query: "Milton",
    },
  ];

  return (
    <div className="row">
      <div className="MapandListing_container">
        <div className="flex-column w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              ref={cardRef}
              className={`${
                showListing
                  ? "onlyListing_cards"
                  : `MapandListingcard_container ${
                      isSearchPage ? "searchPage_cardsContainer" : ""
                    }`
              }
               ${MapListing ? "d-none w-0" : ""}`}
              id="mapcard"
            >
              <div
                className={`${showListing ? "d-flex flex-column w-100" : ""}`}
              >
                {!isLoading &&
                (filterDataSlice?.length === 0 ||
                  filterDataSlice[0]?.length === 0) ? (
                  <div className={MixedListing ? "No_found_centerDiv" : ""}>
                    <ResultNotFound />
                  </div>
                ) : (
                  <>
                    <MapListingCards
                      isLoading={isLoading}
                      handleHoveredListCard={handleHoveredListCard}
                    />
                    {showMore && (showListing || MixedListing) ? (
                      <div className="justifycenter aligncenter mt-4 mb-2">
                        {loadMore ? (
                          <RotatingLines width={50} strokeColor="grey" />
                        ) : (
                          <button
                            className="btn btn-primary font"
                            onClick={handleMoreDeals}
                            disabled={loadMore}
                          >
                            Load more
                          </button>
                        )}
                      </div>
                    ) : null}

                    {isSearchPage && (MixedListing || showListing) && (
                      <ListingSeo />
                    )}

                    {/* Popular Searches Section */}
                    {isSearchPage && (MixedListing || showListing) && (
                      <div className="mt-5 pe-3">
                        <SearchingSuggestion
                          heading={"Popular Searches"}
                          supportingLine={
                            "Find what others are looking for—explore the most sought-after properties and trends."
                          }
                          data={popularSearches}
                          isSearchPage={isSearchPage}
                          setNewInputVal={setNewInputVal}
                          setSearchResTitle={setSearchResTitle}
                          setSearchValue={setSearchValue}
                          setSearchResDes={setSearchResDes}
                          setSelectedValues={setSelectedValues}
                        />
                      </div>
                    )}

                    {/* NearBy cities Section */}
                    {isSearchPage && (MixedListing || showListing) && (
                      <div className="mt-5 pe-3">
                        <SearchingSuggestion
                          heading={"Nearby Cities"}
                          supportingLine={
                            "Discover nearby cities and expand your search for the perfect property."
                          }
                          data={nearBycities}
                          isSearchPage={isSearchPage}
                          setNewInputVal={setNewInputVal}
                          setSearchResTitle={setSearchResTitle}
                          setSearchValue={setSearchValue}
                          setSearchResDes={setSearchResDes}
                          setSelectedValues={setSelectedValues}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {isMobileView && showButton && (
                <div className="list_btnscroll" onClick={handleMapClick}>
                  <button className="d-flex">
                    <MapIcon style={{ color: "#fff" }} />
                    <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                      Map
                    </span>
                  </button>
                </div>
              )}
            </div>
            <div
              className={`${
                MapListing
                  ? "onlymap_show"
                  : `MapandListing_map Mobile__Hide ${
                      isSearchPage ? "searchPage_mapContainer" : ""
                    } `
              } ${showListing ? "d-none w-0" : ""}`}
            >
              <MapBox
                centerPopUP={centerPopUP}
                hoveredSlug={hoveredSlug}
                toggleIcon={toggleIcon}
                insidePolygon={insidePolygon}
                setInsidePolygon={setInsidePolygon}
                categoryLocation={categoryLocation}
                mapRef={mapRef}
                isLoading={isLoading}
                resetTrigger={resetTrigger}
                isMobileView={isMobileView}
                isFocusOnMap={isFocusOnMap}
                searchValue={searchValue}
              />
              {isMobileView && showMapButton && (
                <div className="list_btnscroll" onClick={handleListClick}>
                  <button className="d-flex">
                    <MapIcon style={{ color: "#fff" }} />
                    <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                      List
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
