import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../HomePage";
import ContactUs from "../Components/ContactUs/ContactUs";
import Faqs from "../Components/Faqs/Faqs";
import LoginPage from "../Components/LoginPage/LoginPage";
import Signup from "../Components/SignUp/Signup";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import EmailConfirmation from "../Components/EmailConfirmation/EmailConfirmation";
import HowItWorks from "../Components/HowitWorks/HowItWorks";
import TermsAndConditions from "../Components/TermsAndConditions/TermsAndConditions";
import SearchAgent from "../Components/SearchAgent/SearchAgent";
import SubmitAssignment from "../Components/SubmitAssignment/SubmitAssignment";
import FindDeals from "../Components/FindDeals/FindDeals";
import WithNavbarLayout from "./WithNavbarLayout";
import ConfirmationEmail from "../Dashboard/Components/Security/EmailConfirmation";
import NewPassword from "../Components/ResetPassword/NewPassword";
import PrivacyAndPolicy from "../Components/PrivacyAndPolicy/PrivacyAndPolicy";
import CookiePolicy from "../Components/CookiePolicy/CookiePolicy";
import NotFoundPage from "../Components/404Page/NotfoundPage";
import MortgageAdvisor from "../Components/MortgageAdvisor/MortgageAdvisor";
import MortgageAdvisorDetails from "../Components/MortgageAdvisor/MortgageAdvisorDetails";
import SellProperty from "../Components/SellProperty/SellProperty";
import GetQuotes from "../Components/GetQuotes/GetQuotes";
import ContactMessageRec from "../Components/ContactUs/ContactThanksPage";
import FinanceOption from "../Components/FinanceOption/FinanceOptions";
import CompanyAdvertise from "../Components/AdvertiseCompany/AdvertiseCompany";
import Press from "../Components/Press/Press";
import PrivateRoute from "./PrivateRoutes";
import ToolsToPromoteAd from "../Components/ToolsToPromoteAd/ToolsToPromoteAd";
import About from "../Components/About/About";
import AdvertiseWithUS from "../Components/AdvertiseWithUs/AdvertiseWithUS";
import WhyBuyHomeWithUs from "../Components/WhyBuyHomeWithUs/WhyBuyHomeWithUS";
import Blogs from "../Components/Blogs/Blogs";
import BlogDetails from "../Components/Blogs/SubComponents/BlogDetails/BlogDetails";
import CategoryWiseAssignment from "../Components/CategoryWiseAssignment/CategoryWiseAssignment";
import { Helmet } from "react-helmet";
import PropertyDetails from "../Components/PropertyDetails/PropertyDetails";
import FloorPlanDetails from "../Components/FLoorPlanDetails/FloorPlanDetails";
import AssignmentDetails from "../Components/AssignmentDetails/AssignmentDetails";
import ResaleDetails from "../Components/ResaleDetails/ResaleDetails";
import RealtorProfile from "../Components/RealtorProfile";
import SearchPage from "../Components/SearchPage";
import ComingSoon from "../Components/ComingSoon";
import SubscriberInfo from "../Components/ComingSoon/SubscriberInfo";
import ThanksSubscriber from "../Components/ComingSoon/Thanks";

const WebsiteRoutes = () => {
  return (
    <>
      <Helmet>
        <title>AssignmentSold.ca</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<ComingSoon />}/>
        <Route path="/user-information" element={<SubscriberInfo />}/>
        <Route path="/thanks" element={<ThanksSubscriber />}/>
        {/* <Route
          path="/"
          element={
            <WithNavbarLayout>
              <Home />
            </WithNavbarLayout>
          }
        /> */}
        <Route
          path="/:id"
          element={
            <WithNavbarLayout>
              <SearchPage />
            </WithNavbarLayout>
          }
        />
        <Route path="assignment-sale/:id" element={<AssignmentDetails />} />
        <Route path="preconstruction/:id" element={<PropertyDetails />} />
        {/* <Route path="assignment-detail" element={<AssignmentDetails />} /> */}
        <Route path="resale-detail" element={<ResaleDetails />} />
        <Route path="floor-plan/:id" element={<FloorPlanDetails />} />
        <Route
          path="assignment-sales/:id"
          element={
            <WithNavbarLayout>
              <CategoryWiseAssignment />
            </WithNavbarLayout>
          }
        />
        <Route
          path="submit-assignment"
          element={<PrivateRoute Component={SubmitAssignment} />}
        />
        <Route
          path="submit-assignment/:assignmentId"
          element={<PrivateRoute Component={SubmitAssignment} />}
        />
        <Route
          path="get-quotes"
          element={<PrivateRoute Component={GetQuotes} />}
        />
        <Route
          path="contact-us"
          element={
            <WithNavbarLayout>
              <ContactUs />
            </WithNavbarLayout>
          }
        />
        <Route
          path="contact-us/response"
          element={
            <WithNavbarLayout>
              <ContactMessageRec />
            </WithNavbarLayout>
          }
        />
        <Route
          path="faqs"
          element={
            <WithNavbarLayout>
              <Faqs />
            </WithNavbarLayout>
          }
        />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<Signup />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="email-confirmation" element={<EmailConfirmation />} />
        <Route
          path="how-it-works"
          element={
            <WithNavbarLayout>
              <HowItWorks />
            </WithNavbarLayout>
          }
        />
        <Route
          path="terms-and-conditions"
          element={
            <WithNavbarLayout>
              <TermsAndConditions />
            </WithNavbarLayout>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <WithNavbarLayout>
              <PrivacyAndPolicy />
            </WithNavbarLayout>
          }
        />
        <Route
          path="cookie-policy"
          element={
            <WithNavbarLayout>
              <CookiePolicy />
            </WithNavbarLayout>
          }
        />
        <Route
          path="finance-details"
          element={
            <WithNavbarLayout>
              <FinanceOption />
            </WithNavbarLayout>
          }
        />
        <Route
          path="search-agent"
          element={
            <WithNavbarLayout>
              <SearchAgent />
            </WithNavbarLayout>
          }
        />
        <Route path="/realtor-profile" element={<RealtorProfile />} />
        <Route
          path="find-deals/:id"
          element={
            <WithNavbarLayout>
              <FindDeals />
            </WithNavbarLayout>
          }
        />
        <Route
          path="mortgage-advisor"
          element={
            <WithNavbarLayout>
              <MortgageAdvisor />
            </WithNavbarLayout>
          }
        />
        <Route
          path="mortgage-advisor/:id"
          element={
            <WithNavbarLayout>
              <MortgageAdvisorDetails />
            </WithNavbarLayout>
          }
        />
        <Route path="Security/ConfirmEmail" element={<ConfirmationEmail />} />
        <Route path="Security/confirm-reset-code" element={<NewPassword />} />
        <Route
          path="sell"
          element={
            <WithNavbarLayout>
              <SellProperty />
            </WithNavbarLayout>
          }
        />
        <Route
          path="company-advertisement"
          element={
            <WithNavbarLayout>
              <CompanyAdvertise />
            </WithNavbarLayout>
          }
        />
        <Route
          path="press"
          element={
            <WithNavbarLayout>
              <Press />
            </WithNavbarLayout>
          }
        />
        <Route
          path="about"
          element={
            <WithNavbarLayout>
              <About />
            </WithNavbarLayout>
          }
        />
        <Route
          path="advertise-with-us"
          element={
            <WithNavbarLayout>
              <AdvertiseWithUS />
            </WithNavbarLayout>
          }
        />
        <Route
          path="buy-home-with-us"
          element={
            <WithNavbarLayout>
              <WhyBuyHomeWithUs />
            </WithNavbarLayout>
          }
        />
        <Route
          path="tools-to-promote-ad"
          element={
            <WithNavbarLayout>
              <ToolsToPromoteAd />
            </WithNavbarLayout>
          }
        />
        <Route
          path="blogs/:id"
          element={
            <WithNavbarLayout>
              <Blogs />
            </WithNavbarLayout>
          }
        />
        <Route
          path="blogs/details/:id"
          element={
            <WithNavbarLayout>
              <BlogDetails />
            </WithNavbarLayout>
          }
        />
        <Route
          path="*"
          element={
            <WithNavbarLayout>
              <NotFoundPage />
            </WithNavbarLayout>
          }
        />
        {/* Other routes... */}
      </Routes>
    </>
  );
};

export default WebsiteRoutes;
